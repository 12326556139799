export const ACTIONS = {
  FETCH_CONTENT: 'fetchContent',
  FETCH_AND_PATCH_CONTENT: 'fetchAndPatchContent'
}

export const MUTATIONS = {
  PATCH_CONTENT: 'patchContent',
  SET_MODULE_ID: 'setModuleId',
  SET_CONTENT: 'setContent',
  SET_SERVER_CONTENT: 'setServerContent',
  SET_SIDEBAR_EXPANDED_ITEMS: 'setSidebarExpandedItems',
  SET_LOADING_VALUE: 'setLoadingValue',
  INCREASE_LOADING_VALUE: 'increaseLoadingValue',
  DECREASE_LOADING_VALUE: 'decreaseLoadingValue',
  SET_SELECTED_SITE_ID: 'setSelectedSiteId',
  SET_ZOOM: 'setZoom',
  SET_DEVICE: 'setDevice',
  SET_ORIENTATION: 'setOrientation',
  SET_THEME: 'setTheme',
  SET_EDIT_MODE: 'setEditMode',
  SET_TRANSLATE_MODE: 'setTranslateMode',
  SET_ASSET_PANEL_VIEW: 'setAssetPanelView',
  SET_SIDEBAR_ACTIVE: 'setSidebarActive',
  SET_ASSET_PANEL_OPEN: 'setAssetPanelOpen',
  SET_TEST: 'setTest',

  SET_USER_INPUT: 'setUserInput',
  RESET_USER_INPUT: 'resetUserInput',
  REGISTER_ACTIVE_COMPONENT_ID: 'registerActiveComponentId',
  UNREGISTER_ACTIVE_COMPONENT_ID: 'unregisterActiveComponentId',
  UNREGISTER_ALL_ACTIVE_COMPONENT_IDS: 'unregisterAllActiveComponentIds',

  SET_DIRTY: 'setDirty',
  SET_CONTENT_UPDATING: 'setContentUpdating',

  SET_SELECTED_COMPONENT_ID: 'setSelectedComponentId',
  SET_COMPONENT_MAPPING_VALUE: 'setComponentMappingValue',
  RESET_COMPONENT_MAPPING: 'resetComponentMapping',

  SET_DRAGGED_ITEM_IDENTIFIER: 'setDraggedItemIdentifier',
  SET_DRAGGED_ITEM_DROP_SUCCESSFUL: 'setDraggedItemDropSuccessful',
  SET_KICKED_OUT_OF_DROP_ZONE_ITEM: 'setKickedOutOfDropZoneItem',

  SET_HOVERED_TREE_COMPONENT_ID: 'setHoveredTreeComponentId',
  SET_OPENED_TREE_COMPONENT_ID: 'setOpenedTreeComponentId',

  SET_STRUCTURE_CHAPTER_DRAG_ACTIVE: 'setStructureChapterDragActive',
  SET_DRAG_DROP_COMPONENT_DATA: 'setDragDropComponentData',
  SET_DRAG_DROP_ASSET_DATA: 'setDragDropAssetData',
  SET_DRAG_DROP_FILE_COUNT: 'setDragDropFileCount',
  SET_DRAG_DROP_FILE_TYPE: 'setDragDropFileType',
  SET_UPLOAD_ACTIVE: 'setUploadActive',

  SET_CONTENT_DRAGGING_DISABLED: 'setEditingTextInEditWrapper',

  SET_DROP_SUCCESSFUL: 'setDropSuccessful',

  SET_POINTER_BLOCK_OVERLAY_VISIBLE: 'setPointerBlockOverlayVisible',

  SET_MASTER_LANGUAGE_CODE: 'setMasterLanguageCode',
  SET_CURRENT_LANGUAGE_CODE: 'setCurrentLanguageCode'
}
