































import Vue from 'vue'

export default Vue.extend({
  name: 'SidebarContent',
  props: {
    title: String
  }
})
