









import Vue from 'vue'
import ComponentLibrary from './ComponentLibrary.vue'

export default Vue.extend({
  name: 'ComponentView',

  components: {
    ComponentLibrary
  },

  props: {
    value: Boolean
  },

  computed: {
    valueProxy: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    }
  },

  watch: {
    value () {
      if (!this.value) return

      window.setTimeout(() => {
        (this.$refs.navigationDrawer as any).callUpdate()
      })
    }
  }
})
