






























import Vue from 'vue'
import { MUTATIONS } from '../store/consts'
import uploadAsset from '@simpl/cms-components/utils/upload-asset'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'AssetUploader',

  model: {},

  props: {
    value: Boolean
  },

  computed: {
    ...mapState('cms', { isUploading: '_isUploading' }),

    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    moduleId (): number {
      return this.$store.state.cms.moduleId
    },
    draggedFilesCount: {
      get (): number {
        return this.$store.state.cms._dragDropFileCount
      },
      set (v: number) {
        this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_COUNT}`, v)
      }
    }
  },

  methods: {
    onInput (fileList: FileList) {
      const files = [...Array.from(fileList)]

      if (!files) {
        return
      }

      this.$store.commit(`cms/${MUTATIONS.SET_UPLOAD_ACTIVE}`, true)

      const uploadFiles = [] as Promise<any>[]

      files.forEach((file) => {
        uploadFiles.push(uploadAsset(this, file))
      })

      Promise.all(uploadFiles).then(async () => {
        this.draggedFilesCount = 0

        this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_TYPE}`, null)
        this.$store.commit(`cms/${MUTATIONS.SET_ASSET_PANEL_OPEN}`, true)

        this.$eventBus.emit('asset-upload')

        this.$store.commit(`cms/${MUTATIONS.SET_UPLOAD_ACTIVE}`, false)
      }).catch(e => console.log(e))
    }
  }
})
