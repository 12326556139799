import type Vue from 'vue'
import { UPLOAD_ASSET } from '@simpl/cms/graphql'

export default function uploadAsset (context: Vue, file: File) {
  return new Promise((resolve, reject) => {
    context.$apollo.mutate({
      mutation: UPLOAD_ASSET,
      fetchPolicy: 'no-cache',
      variables: {
        module_id: context.$store.state.cms.moduleId,
        data: {
          selected_file: file,
          name: file.name,
          languagecode: context.$store.state.auth.user.languagecode
        }
      }
    }).then(res => {
      resolve(res)
    }).catch(e => reject(e))
  })
}
