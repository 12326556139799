


































import Vue from 'vue'
import { ContentTree } from '../../types'
import { MUTATIONS } from '../../store/consts'

export default Vue.extend({
  name: 'ComponentTree',

  props: {
    value: Boolean
  },

  computed: {
    valueProxy: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    selectedContent (): ContentTree {
      return this.$store.getters['cms/selectedContent']
    },
    componentTreeItems (): any[] {
      if (
        !this.selectedContent?.data ||
        !this.selectedContent.children?.length
      ) return []

      const traverse = (item: ContentTree): any => {
        if (!['site', 'component'].includes(item.type)) return false

        return {
          id: item.id,
          children: [...item.children.map(traverse)].filter(Boolean),
          tag: item.data!.tag,
          preview: (this.$options.components![item.data!.tag] as any).options.cms.preview
        }
      }

      return [traverse(this.selectedContent)]
    },
    selectedComponentId: {
      get (): number[] {
        return [this.$store.state.cms.selectedComponentId]
      },
      set (v: number[]) {
        if (v[0] === this.$store.state.cms.selectedComponentId) return

        this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_COMPONENT_ID}`, v[0])
        this.valueProxy = false
      }
    },
    openedTreeComponentIds: {
      get (): (string | number)[] {
        return this.$store.state.cms._openedTreeComponentIds
      },
      set (v: (string | number)[]) {
        this.$store.commit(`cms/${MUTATIONS.SET_OPENED_TREE_COMPONENT_ID}`, v)
      }
    },
    hoveredTreeComponentId: {
      get (): number | null {
        return this.$store.state.cms._hoveredTreeComponentId
      },
      set (v: number) {
        this.$store.commit(`cms/${MUTATIONS.SET_HOVERED_TREE_COMPONENT_ID}`, v)
      }
    }
  },

  watch: {
    value (v) {
      if (!v) return

      window.setTimeout(() => {
        (this.$refs.navigationDrawer as any).callUpdate()
      })
    },
    componentTreeItems: {
      deep: true,
      handler (v) {
        if (!v?.length) return

        this.openedTreeComponentIds = [v[0].id]
      }
    }
  }
})
