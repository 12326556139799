





























































































































import Vue from 'vue'
import { File as GraphQLFile } from '@simpl/core/types/graphql'
import { ASSETS, UPLOAD_ASSET, DEALLOCATE_ASSETS } from '../../graphql'
import AssetUploader from '../AssetUploader.vue'
import AssetTile from './AssetTile.vue'

function orderBy (key: string, order: 'ASC' | 'DESC', a: any, b: any): number {
  const multiplier = order === 'DESC' ? -1 : 1

  if (a[key] < b[key]) return -1 * multiplier
  if (a[key] > b[key]) return 1 * multiplier
  return 0
}

export default Vue.extend({
  name: 'AssetsLibrary',

  components: {
    AssetUploader,
    AssetTile
  },

  props: {
    filterBy: Object as () => {
      name: string,
      value: RegExp | null
    } | null,
    searchValue: String,
    orderBy: Object as () => {
      column: string,
      order: 'ASC' | 'DESC'
    }
  },

  data () {
    return {
      showUploadStatus: false,
      loadingAssets: [] as number[],
      uploadProgress: -1,
      isUploading: false,

      dragOver: false,

      loading: 0,

      moduleAssets: [] as GraphQLFile[],
      uploadedAssets: [] as File[],

      contextAsset: {} as GraphQLFile,
      assetDetailsVisible: false,
      assetDetailsLeft: 0,
      assetDetailsTop: 0
    }
  },

  computed: {
    view (): string {
      return this.$store.state.cms.assetPanelView
    },
    moduleId (): number {
      return this.$store.state.cms.moduleId
    },
    filteredAssets (): GraphQLFile[] {
      let assets = [...this.moduleAssets]
      if (this.searchValue) {
        assets = assets.filter(a => a.filename.toLowerCase().includes(this.searchValue.toLowerCase()))
      }
      if (this.filterBy && this.filterBy.value) {
        assets = assets.filter(a => (a as any)[this.filterBy!.name].match(this.filterBy!.value))
      }
      assets.sort((a, b) => orderBy(this.orderBy.column, this.orderBy.order, a, b))
      return assets
    },
    showAssetUploader (): boolean {
      return this.$store.state.cms._dragDropFileCount > 0
    }
  },

  watch: {
    uploadProgress (v) {
      if (v === 100) {
        setTimeout(() => {
          this.showUploadStatus = true
        }, 500)
      }
    },
    isUploading (v) {
      if (!v) {
        this.loadingAssets = []
        this.showUploadStatus = false
        this.uploadProgress = -1
      }
    }
  },

  apollo: {
    moduleAssets: {
      query: ASSETS,
      variables (): object {
        return {
          /* filter: {
            search: {
              query: this.searchValue,
              columns: ['name']
            },
            filterBy: this.filterBy
          },
          orderBy: this.orderBy, */
          module_id: this.moduleId
        }
      },
      update (result: Record<string, any>): Record<string, any>[] {
        return result.assets
      },
      error (error: Error): void {
        console.error(error)
      },
      loadingKey: 'loading'
    }
  },

  mounted () {
    this.$eventBus.on('asset-upload', this.refetchAssets)
  },

  methods: {
    onInput (fileList: FileList) {
      this.isUploading = true
      const files = [...Array.from(fileList)]
      if (!files) return
      const uploadFiles = [] as Promise<any>[]
      files.forEach((file) => {
        uploadFiles.push(this.uploadFile(file))
      })
      Promise.all(uploadFiles).then(async () => {
        await this.$apollo.queries.moduleAssets.refetch()
        this.isUploading = false
      }).catch(e => console.log(e))
    },
    async deallocateAssets (ids: number[]) {
      this.loading += 1
      await this.$apollo.mutate({
        mutation: DEALLOCATE_ASSETS,
        variables: {
          module_id: this.moduleId,
          asset_ids: ids
        }
      })
      this.loading -= 1
      await this.$apollo.queries.moduleAssets.refetch()
    },

    uploadFile (file: File): Promise<void> {
      const reader = new FileReader()
      reader.onload = ($e: ProgressEvent) => {
        this.loadingAssets.push(($e.target! as any).result)
      }
      reader.readAsDataURL(file as any)
      return new Promise((resolve, reject) => {
        this.$apollo.mutate({
          mutation: UPLOAD_ASSET,
          variables: {
            module_id: this.moduleId,
            data: {
              selected_file: file,
              name: file.name,
              languagecode: this.$store.state.auth.languagecode
            }
          },
          context: {
            fetchOptions: {
              onUploadProgress: (progress: ProgressEvent) => {
                if (progress.lengthComputable) {
                  this.uploadProgress = Math.round(progress.loaded / progress.total) * 100
                } else {
                  this.uploadProgress = -1
                }
              }
            }
          }
        }).then(() => {
          resolve()
        }).catch(e => reject(e))
      })
    },

    async refetchAssets () {
      await this.$apollo.queries.moduleAssets.refetch()
    }
  }
}
)
