var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-height"},[_c('v-item-group',{model:{value:(_vm.selectedComponent),callback:function ($$v) {_vm.selectedComponent=$$v},expression:"selectedComponent"}},_vm._l((_vm.components),function(category,title){return _c('div',{key:title,staticClass:"py-0 px-3 ma-0"},[_c('div',{staticClass:"heading mt-3 mb-1"},[_vm._v(" "+_vm._s(_vm.$t(("cms.categories." + title)))+" ")]),_c('v-container',{staticClass:"component-selection pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((category),function(comp,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('v-item',{attrs:{"value":comp.options.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"component-tile",attrs:{"draggable":"false"}},[_c('div',{staticClass:"py-1 d-flex flex-column fill-height",style:({cursor: _vm.isDialog ? 'pointer' : 'grab'}),attrs:{"elevation":hover ? 4 : '',"color":active ? 'primary' : 'grey',"draggable":"true"},on:{"dragstart":function($event){!_vm.isDialog ? _vm.onDragStart($event, comp) : $event.preventDefault()},"click":function($event){_vm.isDialog && toggle()}}},[_c('div',{staticClass:"flex-grow-1 flex-shrink-0 d-flex align-center justify-center px-3",domProps:{"innerHTML":_vm._s(typeof comp.options.cms.preview === 'function' ? comp.options.cms.preview() : comp.options.cms.preview)}}),_c('div',{staticClass:"text-caption component-name text-center mt-1 px-1",class:{'grey--text': !active},domProps:{"innerHTML":_vm._s(_vm.$t(("cms.names." + (comp.options.name))))}})]),(_vm.$te(("cms.descriptions." + (comp.options.name))))?_c('v-tooltip',{attrs:{"right":"","open-delay":"500","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"description-button",attrs:{"small":""}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.$t(("cms.descriptions." + (comp.options.name))))}})]):_vm._e()],1)]}}],null,true)})]}}],null,true)})],1)}),1)],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }