import gql from 'graphql-tag'

export const GET_MODULE_TEXTS_ONLY = gql`query GET_MODULE_TEXTS_ONLY ($id: ID) {
    module (id: $id) {
        id
        identifier
        type
        languagecode
        texts {
            identifier
            languagecode
            text
        }
    }
}`

export const GET_CONTENT_LIST = gql`query GET_CONTENT_LIST ($id: ID!) {
    contentList (module_id: $id) {
        id
        parent { id }
        type
        data
        properties
        _lft
        _rgt
        texts {
            id
            identifier
            languagecode
            text
        }
    }
}`

export const GET_CONTENT_TREE = gql`query GET_CONTENT_TREE ($moduleId: ID!) {
    contentTree (module_id: $moduleId)
}`

export const VIEW_CONTENT_TREE = gql`query VIEW_CONTENT_TREE ($moduleId: ID!) {
    viewContentTree (module_id: $moduleId)
}`

export const CONTENT_WITHOUT_FILES = gql`query CONTENT ($id: ID!) {
    content (id: $id) {
        id
        module {
            id
        }
        parent {
            id
        }
        type
        data
        properties
        texts {
            identifier
            languagecode
            text
        }
        created_at
        updated_at
    }
}`

export const CREATE_CONTENT = gql`mutation CREATE_CONTENT ($data: NewContentInput!) {
    createContent(data: $data) {
        id
        parent { id }
        type
        data
        properties
        _lft
        _rgt
        texts {
            id
            identifier
            languagecode
            text
        }
    }
}`

export const CREATE_CONTENT_ID_ONLY = gql`mutation CREATE_CONTENT_ID_ONLY ($data: NewContentInput!) {
    createContent(data: $data) { id }
}`

export const APPEND_CONTENT = gql`mutation APPEND_CONTENT ($id: ID!, $data: NewContentInput!) {
    appendContent(id: $id, data: $data)
}`

export const DELETE_CONTENT = gql`mutation DELETE_CONTENT ($id: ID!) {
    deleteContent(id: $id) {
        id
    }
}`

export const UPDATE_CONTENT = gql`mutation UPDATE_CONTENT ($data: UpdateContentInput!) {
    updateContent(data: $data) {
      id
    }
}`

export const UPDATE_AND_FETCH_CONTENT = gql`mutation UPDATE_AND_FETCH_CONTENT ($data: UpdateContentInput!) {
    updateContent(data: $data) {
        id
        parent { id }
        type
        data
        properties
        _lft
        _rgt
        texts {
            id
            identifier
            languagecode
            text
        }
    }
}`

export const UPDATE_CONTENT_TREE = gql`mutation UPDATE_CONTENT_TREE (
    $parent_id: ID!
    $trees: [UpdateContentTreeInput]
    $delete: [ID]
) {
    updateContentTree(parent_id: $parent_id, trees: $trees, delete: $delete)
}`

export const REQUEST_PREVIEW_TOKEN = gql`mutation REQUEST_PREVIEW_TOKEN ($module_id: ID!) {
    requestPreviewToken(module_id: $module_id)
}`

export const GET_CONTENT_PREVIEW = gql`query GET_CONTENT_PREVIEW ($token: String!) {
    contentPreview(token: $token) {
        content
        expired
    }
}`

export const CREATE_CONTENT_PRESET = gql`mutation CREATE_CONTENT_PRESET ($data: NewContentPresetInput!) {
    createContentPreset(data: $data) {
        id
        domain_id
        module_id
        type
        data
        properties
    }
}`

export const UPDATE_CONTENT_PRESET = gql`mutation UPDATE_CONTENT_PRESET ($data: UpdateContentPresetInput!) {
    updateContentPreset(data: $data) {
        id
        domain_id
        module_id
        type
        data
        properties
        tags {
          id
          category {
            id
            identifier
            texts {
              id
              identifier
              languagecode
              text
            }
          }
          identifier
          texts {
            id 
            identifier
            text
            languagecode
          }
        }
    }
}`

export const GET_CONTENT_PRESETS = gql`query GET_CONTENT_PRESETS (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    contentPresets (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
      data {
        id
        domain_id
        module_id
        type
        data
        thumb_desktop
        thumb_mobile
        properties
        texts {
          identifier
          languagecode
          text
        }
        tags {
          id
          category {
            id
            identifier
            texts {
              id
              identifier
              languagecode
              text
            }
          }
          identifier
          texts {
            id 
            identifier
            text
            languagecode
          }
        }
        created_at
        updated_at
      }
      paginatorInfo {
        count 
        currentPage
        hasMorePages
        lastPage
        perPage
        total
      }
    }
}`

export const DELETE_CONTENT_PRESET = gql`mutation DELETE_CONTENT_PRESET ($id: ID!) {
    deleteContentPreset(id: $id) {
      id
    }
}`

export const UPLOAD_ASSET = gql`mutation UPLOAD_ASSET ($module_id: ID!, $data: UploadAssetInput!) {
    uploadAsset (module_id: $module_id, data: $data) {
      id
      slug
      languagecode
      filename
      size
      url
      thumbnail
      mime_type
    }
}`

export const ASSETS = gql`query ASSETS ($module_id: ID) {
    assets (module_id: $module_id) {
        id
        slug
        name
        languagecode
        mime_type
        created_at
        url
    }
}`

export const DEALLOCATE_ASSETS = gql`mutation DEALLOCATE_ASSETS ($module_id: ID!, $asset_ids: [ID]!) {
    deallocateAssets (module_id: $module_id, asset_ids: $asset_ids)
}`

export const CONTENT_TREE_UPDATED = gql`subscription CONTENT_TREE_UPDATED ($module_id: ID!, $user_id: ID) {
    contentTreeUpdated(module_id: $module_id, user_id: $user_id)
}`

export const PREV_SIBLING = gql`query PREV_SIBLING ($id: ID!) {
    prevSibling(node_id: $id) {
        id
        parent { id }
        type
        data
        properties
        _lft
        _rgt
        texts {
            id
            identifier
            languagecode
            text
        }
    }
}`

export const PREV_SIBLINGS = gql`query PREV_SIBLINGS ($id: ID!) {
    prevSiblings(node_id: $id) {
        id
        parent { id }
        type
        data
        properties
        _lft
        _rgt
        texts {
            id
            identifier
            languagecode
            text
        }
    }
}`
