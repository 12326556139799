


















import Vue from 'vue'

export default Vue.extend({
  name: 'SidebarItem',

  props: {
    disabled: Boolean,
    icon: String,
    title: String
  }
})
