






























































































































import Vue from 'vue'
import AssetsLibrary from './AssetsLibrary.vue'
import { MUTATIONS } from '../../store/consts'

export default Vue.extend({
  name: 'AssetsPanel',

  components: {
    AssetsLibrary
  },

  props: {
    value: Boolean
  },

  data () {
    const types = [
      { text: 'allAssets', value: null },
      { text: 'images', value: /image\/./, icon: 'mdi-image' },
      { text: 'videos', value: /video\/./, icon: 'mdi-video' },
      { text: 'lottie', value: /application\/json/, icon: 'mdi-animation-play' }
    ]
    return {
      showFilter: false,
      types,
      selectedType: types[0],
      orderOptions: [
        { text: 'zToA', column: 'name', value: 'DESC' },
        { text: 'aToZ', column: 'name', value: 'ASC' },
        { text: 'oldest', column: 'created_at', value: 'ASC' },
        { text: 'newest', column: 'created_at', value: 'DESC' }
      ],
      selectedOrderOptionIndex: 3,
      views: [
        { icon: 'mdi-grid', key: 'grid' },
        { icon: 'mdi-view-list', key: 'list' }
      ]
    }
  },

  computed: {
    view: {
      get (): string {
        return this.$store.state.cms.assetPanelView
      },
      set (v: string) {
        this.$store.commit(`cms/${MUTATIONS.SET_ASSET_PANEL_VIEW}`, v)
      }
    },
    filterBy (): Record<string, any> | null {
      return this.selectedType?.value
        ? {
          name: 'mime_type',
          value: this.selectedType.value
        }
        : null
    },
    orderBy (): Record<string, any> {
      return {
        column: this.orderOptions[this.selectedOrderOptionIndex].column,
        order: this.orderOptions[this.selectedOrderOptionIndex].value
      }
    }
  },

  watch: {
    value: {
      handler (v) {
        if (!this.value) return
        window.setTimeout(() => {
          (this.$refs.navigationDrawer as any).callUpdate()
        })
      },
      immediate: true
    }
  }
})
