














































































import Vue from 'vue'
import { MUTATIONS } from '../../store/consts'
import { DragDropComponentData } from '../../store/types'
import type { VueConstructor } from 'vue/types/vue'

const sortByOrder = (a: any, b: any) => {
  const aOrder = a.options.cms?.category?.order || 0
  const bOrder = b.options.cms?.category?.order || 0
  return aOrder > bOrder ? 1 : (bOrder > aOrder ? -1 : 0)
}

export default Vue.extend({
  name: 'ComponentLibrary',

  props: {
    isDialog: {
      type: Boolean,
      default: false
    },
    selected: String
  },

  data () {
    return {
      boundOnDragEnd: null! as () => void
    }
  },

  computed: {
    components (): Record<string, Vue[]> {
      const components: any[] = []

      for (const comp in this.$options.components) {
        const component = this.$options.components[comp] as VueConstructor
        if (component?.options?.cms) {
          components.push(component)
        }
      }

      return components.filter(c => !c.options.cms?.hideInLibrary).reduce((acc, v) => {
        const categoryData = v.options.cms!.category!
        const categoryName = categoryData.key
        const category = acc[categoryName] || (acc[categoryName] = [])

        category.push(v)
        category.sort(sortByOrder)

        return acc
      }, {} as Record<string, Vue[]>)
    },
    selectedComponent: {
      get () {
        return this.selected
      },
      set (v) {
        this.$emit('update:selected', v)
      }
    }
  },

  watch: {
    showSearch (v) {
      if (!v) return

      setTimeout(() => {
        (this.$refs.searchField as HTMLInputElement).focus()
      }, 0)
    }
  },

  mounted () {
    this.boundOnDragEnd = () => this.$store.state.cms._draggedLibraryComponent && this.onDragEnd()
    window.addEventListener('dragend', this.boundOnDragEnd)
  },
  beforeDestroy () {
    window.removeEventListener('dragend', this.boundOnDragEnd)
  },

  methods: {
    onDragStart ($e: DragEvent, comp: VueConstructor) {
      $e.dataTransfer?.setData('cms/component', comp.options.name!)

      this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_COMPONENT_DATA}`, {
        tag: comp.options.name!
      } as DragDropComponentData)
    },
    onDragEnd () {
      const success = this.$store.state.cms._dropSuccessful
      this.$store.commit(`cms/${MUTATIONS.SET_DROP_SUCCESSFUL}`, null)
    }
  }
}
)
