







































import Vue from 'vue'
import StructureView from './structure-view'
import ComponentView from './ComponentView.vue'
import ComponentTree from './ComponentTree.vue'
import AssetsPanel from './AssetsPanel.vue'
import SidebarItem from './SidebarItem.vue'
import SidebarContent from './SidebarContent.vue'
import { MUTATIONS } from '../../store/consts'

export default Vue.extend({
  name: 'Sidebar',

  components: {
    SidebarItem,
    SidebarContent,
    StructureView,
    ComponentView,
    ComponentTree,
    AssetsPanel
  },

  data: function () {
    return {
      items: [
        {
          title: this.$t('cms.editSidebar.contentTree'),
          icon: 'mdi-file-tree',
          Component: StructureView,
          showInTranslationMode: true,
          showInPreviewMode: true
        },
        {
          title: this.$t('cms.editSidebar.components'),
          icon: 'mdi-puzzle',
          Component: ComponentView,
          disableIfNoSiteSelected: true
        },
        {
          title: this.$t('cms.editSidebar.siteStructure'),
          icon: 'mdi-filter-variant',
          Component: ComponentTree,
          disableIfNoSiteSelected: true,
          disableOnEmptySite: true
        },
        {
          title: this.$t('cms.editSidebar.mediaLibrary'),
          icon: 'mdi-folder-multiple-image',
          Component: AssetsPanel,
          disableIfNoSiteSelected: true
        }
      ]
    }
  },

  computed: {
    activePanel: {
      get (): string {
        return this.$store.state.cms.sidebarActive
      },
      set (v: number | undefined) {
        this.$store.commit(`cms/${MUTATIONS.SET_SIDEBAR_ACTIVE}`, v)
      }
    },
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    preview (): boolean {
      return !this.$store.state.cms.editMode
    },
    isSiteSelected (): boolean {
      return !!this.$store.getters['cms/selectedContent']
    },
    isSiteEmpty (): boolean {
      return !this.$store.getters['cms/selectedContent'].children.length
    },
    currentItems () {
      return this.items
        .filter((item) => {
          if (this.translateMode) return item.showInTranslationMode
          if (this.preview) return item.showInPreviewMode
          return true
        }).map((item) => {
          if (item.disableIfNoSiteSelected && !this.isSiteSelected) {
            return { ...item, disabled: true }
          }
          if (item.disableOnEmptySite && this.isSiteEmpty) {
            return { ...item, disabled: true }
          }
          return item
        })
    }
  }
})
