





























































































































import Vue from 'vue'
import { File as GraphQLFile } from '@simpl/core/types/graphql'
import { MUTATIONS } from '../../store/consts'
import type { VueConstructor } from 'vue/types/vue'

export default Vue.extend({
  name: 'AssetTile',

  components: {
    LottiePlayer: () => import('@simpl/cms-components/components/LottiePlayer.vue')
  },

  props: {
    file: Object as () => GraphQLFile
  },

  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL || '',

      contextAsset: {} as GraphQLFile,
      assetDetailsVisible: false,
      assetDetailsLeft: 0,
      assetDetailsTop: 0
    }
  },

  computed: {
    assetTile (): HTMLElement {
      return this.$refs.assetTile as HTMLElement
    },
    mediaComponentTypes (): Record<string, any>[] {
      return [{
        type: 'video',
        name: 'CVideoPlayer',
        typeAsPropKey: true
      }, {
        type: 'audio',
        name: 'CAudioPlayer',
        typeAsPropKey: true
      }, {
        type: 'image',
        name: 'CImage',
        typeAsPropKey: true
      }, {
        type: 'application/json',
        name: 'CLottiePlayer'
      }]
    },
    componentType (): Record<string, any> | undefined {
      return this.mediaComponentTypes.find((c: any) => this.file.mime_type.includes(c.type))
    }
  },

  methods: {
    onDragStart (e: DragEvent, asset: GraphQLFile) {
      if (!this.componentType) return

      e.dataTransfer?.setData('cms/asset', asset.url!)

      if (/image\/./.test(asset.mime_type)) {
        const image = new Image()
        image.src = asset.url!
        image.onload = () => {
        }
      }

      const component = Vue.component(this.componentType.name) as VueConstructor
      e.dataTransfer?.setData('cms/component', (component as any).options.name!)

      const data = this.componentType.typeAsPropKey
        ? {
          [this.componentType.type]: {
            url: asset.url!,
            slug: asset.slug!
          }
        }
        : {
          url: asset.url!
        }

      this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_COMPONENT_DATA}`, {
        tag: this.componentType.name,
        data: data
      })

      this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_ASSET_DATA}`, asset)
      this.$emit('drag-start')
    },

    onDragEnd () {
      const success = this.$store.state.cms._dropSuccessful
      this.$emit('drag-end', success)

      this.$store.commit(`cms/${MUTATIONS.SET_DROP_SUCCESSFUL}`, false)
      this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_ASSET_DATA}`, null)
    },

    async showAssetDetails (asset: GraphQLFile, e: MouseEvent) {
      e.preventDefault()
      e.stopPropagation()

      if (this.contextAsset === asset && this.assetDetailsVisible) return

      const rect = (e.currentTarget as HTMLElement).getBoundingClientRect()
      this.assetDetailsLeft = rect.right
      this.assetDetailsTop = rect.top

      window.setTimeout(() => {
        this.contextAsset = asset
        this.assetDetailsVisible = true
      }, 50)
    }
  }
})
